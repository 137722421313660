<template>
  <!-- form -->
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(sendEmailForgotPassword)">
      <!-- email -->
      <b-form-group label="Email" label-for="email">
        <ValidationProvider
          name="Correo"
          rules="required|email"
          v-slot="{ errors }"
        >
          <b-form-input id="email" v-model="client.email" name="email" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- submit button -->
      <div class="text-center">
        <b-button type="submit" variant="primary">
          <feather-icon icon="SendIcon" size="16" /> Enviar
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
//Composition api
import { ref } from '@vue/composition-api'
//Services
import authService from '@/services/auth.service'
//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'
//Notification toast
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Bootsrap vue
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormSelect,
  },

  setup(props, { emit }) {
    const toast = useToast()

    const baseClient = {
      email: '',
    }
    const client = ref(JSON.parse(JSON.stringify(baseClient)))

    const sendEmailForgotPassword = async () => {
      try {
        const { data: res } = await authService.sendEmailForgotPassword(
          client.value
        )

        if (!res.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'warning',
            },
          })
          return
        }

        client.value = JSON.parse(JSON.stringify(baseClient))

        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        emit('executeSendEmailForgotPassword', res.data)
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
      }
    }

    return {
      sendEmailForgotPassword,
      client,
    }
  },
}
</script>

<style scoped></style>
